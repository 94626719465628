import { z } from 'zod'
import { CustomFieldTypeSchema } from '../enums/CustomFieldType.schema'
import { JsonNullValueInputSchema } from '../enums/JsonNullValueInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const literalSchema = z.union([z.string(), z.number(), z.boolean()])
const jsonSchema: z.ZodType<Prisma.InputJsonValue> = z.lazy(() =>
  z.union([
    literalSchema,
    z.array(jsonSchema.nullable()),
    z.record(jsonSchema.nullable()),
  ]),
)

const Schema: z.ZodType<Prisma.CustomFieldCreateWithoutProductCustomFieldInput> =
  z
    .object({
      createdAt: z.date().optional(),
      updatedAt: z.date().optional(),
      fieldType: z.lazy(() => CustomFieldTypeSchema),
      priority: z.number().optional(),
      fieldOptionsTr: z.union([
        z.lazy(() => JsonNullValueInputSchema),
        z.object({ en: z.string(), th: z.string() }),
      ]),
      active: z.boolean().optional(),
      searchField: z.string(),
    })
    .strict()

export const CustomFieldCreateWithoutProductCustomFieldInputObjectSchema =
  Schema
