import { z } from 'zod'
import { ProductCustomFieldFindManySchema } from '../findManyProductCustomField.schema'
import { CustomFieldCountOutputTypeArgsObjectSchema } from './CustomFieldCountOutputTypeArgs.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.CustomFieldInclude> = z
  .object({
    productCustomField: z
      .union([z.boolean(), z.lazy(() => ProductCustomFieldFindManySchema)])
      .optional(),
    _count: z
      .union([
        z.boolean(),
        z.lazy(() => CustomFieldCountOutputTypeArgsObjectSchema),
      ])
      .optional(),
  })
  .strict()

export const CustomFieldIncludeObjectSchema = Schema
