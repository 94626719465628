import { z } from 'zod'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.CustomFieldCountOutputTypeSelect> = z
  .object({
    productCustomField: z.boolean().optional(),
  })
  .strict()

export const CustomFieldCountOutputTypeSelectObjectSchema = Schema
