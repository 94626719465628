import { z } from 'zod'
import { BrandArgsObjectSchema } from './BrandArgs.schema'
import { ProductCategoryFindManySchema } from '../findManyProductCategory.schema'
import { VariantFindManySchema } from '../findManyVariant.schema'
import { ProductPictureFindManySchema } from '../findManyProductPicture.schema'
import { ProductVideoFindManySchema } from '../findManyProductVideo.schema'
import { ProductReviewFindManySchema } from '../findManyProductReview.schema'
import { ProductReviewSummaryArgsObjectSchema } from './ProductReviewSummaryArgs.schema'
import { ProductCustomFieldFindManySchema } from '../findManyProductCustomField.schema'
import { ProductCountOutputTypeArgsObjectSchema } from './ProductCountOutputTypeArgs.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const Schema: z.ZodType<Prisma.ProductSelect> = z
  .object({
    id: z.boolean().optional(),
    active: z.boolean().optional(),
    searchable: z.boolean().optional(),
    nameTr: z.boolean().optional(),
    searchField: z.boolean().optional(),
    regNo: z.boolean().optional(),
    descriptionTr: z.boolean().optional(),
    auxiliaryLabel: z.boolean().optional(),
    instructionTr: z.boolean().optional(),
    sideEffectTr: z.boolean().optional(),
    cautionTr: z.boolean().optional(),
    pharmacyNote: z.boolean().optional(),
    ingredient: z.boolean().optional(),
    type: z.boolean().optional(),
    drugType: z.boolean().optional(),
    salesType: z.boolean().optional(),
    productForms: z.boolean().optional(),
    skinType: z.boolean().optional(),
    propertyTr: z.boolean().optional(),
    concerns: z.boolean().optional(),
    attributes: z.boolean().optional(),
    priority: z.boolean().optional(),
    usage: z.boolean().optional(),
    useAmount: z.boolean().optional(),
    useUnit: z.boolean().optional(),
    useFrequency: z.boolean().optional(),
    useTime: z.boolean().optional(),
    brand: z
      .union([z.boolean(), z.lazy(() => BrandArgsObjectSchema)])
      .optional(),
    brandId: z.boolean().optional(),
    categories: z
      .union([z.boolean(), z.lazy(() => ProductCategoryFindManySchema)])
      .optional(),
    variants: z
      .union([z.boolean(), z.lazy(() => VariantFindManySchema)])
      .optional(),
    pictures: z
      .union([z.boolean(), z.lazy(() => ProductPictureFindManySchema)])
      .optional(),
    video: z
      .union([z.boolean(), z.lazy(() => ProductVideoFindManySchema)])
      .optional(),
    createdAt: z.boolean().optional(),
    updatedAt: z.boolean().optional(),
    deletedAt: z.boolean().optional(),
    v2DrugItemId: z.boolean().optional(),
    productReviews: z
      .union([z.boolean(), z.lazy(() => ProductReviewFindManySchema)])
      .optional(),
    productReviewSummary: z
      .union([z.boolean(), z.lazy(() => ProductReviewSummaryArgsObjectSchema)])
      .optional(),
    productOnCustomField: z
      .union([z.boolean(), z.lazy(() => ProductCustomFieldFindManySchema)])
      .optional(),
    _count: z
      .union([
        z.boolean(),
        z.lazy(() => ProductCountOutputTypeArgsObjectSchema),
      ])
      .optional(),
  })
  .strict()

export const ProductSelectObjectSchema = Schema
