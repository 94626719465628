import { z } from 'zod'
import { IntFieldUpdateOperationsInputObjectSchema } from './IntFieldUpdateOperationsInput.schema'
import { BoolFieldUpdateOperationsInputObjectSchema } from './BoolFieldUpdateOperationsInput.schema'
import { JsonNullValueInputSchema } from '../enums/JsonNullValueInput.schema'
import { StringFieldUpdateOperationsInputObjectSchema } from './StringFieldUpdateOperationsInput.schema'
import { AuxiliaryLabelSchema } from '../enums/AuxiliaryLabel.schema'
import { NullableEnumAuxiliaryLabelFieldUpdateOperationsInputObjectSchema } from './NullableEnumAuxiliaryLabelFieldUpdateOperationsInput.schema'
import { NullableStringFieldUpdateOperationsInputObjectSchema } from './NullableStringFieldUpdateOperationsInput.schema'
import { ProductTypeSchema } from '../enums/ProductType.schema'
import { EnumProductTypeFieldUpdateOperationsInputObjectSchema } from './EnumProductTypeFieldUpdateOperationsInput.schema'
import { DrugTypeSchema } from '../enums/DrugType.schema'
import { NullableEnumDrugTypeFieldUpdateOperationsInputObjectSchema } from './NullableEnumDrugTypeFieldUpdateOperationsInput.schema'
import { SalesTypeSchema } from '../enums/SalesType.schema'
import { EnumSalesTypeFieldUpdateOperationsInputObjectSchema } from './EnumSalesTypeFieldUpdateOperationsInput.schema'
import { UsageSchema } from '../enums/Usage.schema'
import { NullableEnumUsageFieldUpdateOperationsInputObjectSchema } from './NullableEnumUsageFieldUpdateOperationsInput.schema'
import { NullableFloatFieldUpdateOperationsInputObjectSchema } from './NullableFloatFieldUpdateOperationsInput.schema'
import { UnitSchema } from '../enums/Unit.schema'
import { NullableEnumUnitFieldUpdateOperationsInputObjectSchema } from './NullableEnumUnitFieldUpdateOperationsInput.schema'
import { UseFrequencySchema } from '../enums/UseFrequency.schema'
import { EnumUseFrequencyFieldUpdateOperationsInputObjectSchema } from './EnumUseFrequencyFieldUpdateOperationsInput.schema'
import { UseTimeSchema } from '../enums/UseTime.schema'
import { EnumUseTimeFieldUpdateOperationsInputObjectSchema } from './EnumUseTimeFieldUpdateOperationsInput.schema'
import { DateTimeFieldUpdateOperationsInputObjectSchema } from './DateTimeFieldUpdateOperationsInput.schema'
import { NullableDateTimeFieldUpdateOperationsInputObjectSchema } from './NullableDateTimeFieldUpdateOperationsInput.schema'
import { NullableIntFieldUpdateOperationsInputObjectSchema } from './NullableIntFieldUpdateOperationsInput.schema'
import { ProductCategoryUncheckedUpdateManyWithoutProductNestedInputObjectSchema } from './ProductCategoryUncheckedUpdateManyWithoutProductNestedInput.schema'
import { VariantUncheckedUpdateManyWithoutProductNestedInputObjectSchema } from './VariantUncheckedUpdateManyWithoutProductNestedInput.schema'
import { ProductPictureUncheckedUpdateManyWithoutProductNestedInputObjectSchema } from './ProductPictureUncheckedUpdateManyWithoutProductNestedInput.schema'
import { ProductVideoUncheckedUpdateManyWithoutProductNestedInputObjectSchema } from './ProductVideoUncheckedUpdateManyWithoutProductNestedInput.schema'
import { ProductReviewSummaryUncheckedUpdateOneWithoutProductNestedInputObjectSchema } from './ProductReviewSummaryUncheckedUpdateOneWithoutProductNestedInput.schema'
import { ProductCustomFieldUncheckedUpdateManyWithoutProductNestedInputObjectSchema } from './ProductCustomFieldUncheckedUpdateManyWithoutProductNestedInput.schema'

import type { Prisma } from '../../../../../../../node_modules/@prisma/client/products'

const literalSchema = z.union([z.string(), z.number(), z.boolean()])
const jsonSchema: z.ZodType<Prisma.InputJsonValue> = z.lazy(() =>
  z.union([
    literalSchema,
    z.array(jsonSchema.nullable()),
    z.record(jsonSchema.nullable()),
  ]),
)

const Schema: z.ZodType<Prisma.ProductUncheckedUpdateWithoutProductReviewsInput> =
  z
    .object({
      id: z
        .union([
          z.number(),
          z.lazy(() => IntFieldUpdateOperationsInputObjectSchema),
        ])
        .optional(),
      active: z
        .union([
          z.boolean(),
          z.lazy(() => BoolFieldUpdateOperationsInputObjectSchema),
        ])
        .optional(),
      searchable: z
        .union([
          z.boolean(),
          z.lazy(() => BoolFieldUpdateOperationsInputObjectSchema),
        ])
        .optional(),
      nameTr: z
        .union([
          z.lazy(() => JsonNullValueInputSchema),
          z.object({ en: z.string(), th: z.string() }),
        ])
        .optional(),
      searchField: z
        .union([
          z.string(),
          z.lazy(() => StringFieldUpdateOperationsInputObjectSchema),
        ])
        .optional(),
      regNo: z
        .union([
          z.string(),
          z.lazy(() => StringFieldUpdateOperationsInputObjectSchema),
        ])
        .optional(),
      descriptionTr: z
        .union([
          z.lazy(() => JsonNullValueInputSchema),
          z.object({ en: z.string(), th: z.string() }),
        ])
        .optional(),
      auxiliaryLabel: z
        .union([
          z.lazy(() => AuxiliaryLabelSchema),
          z.lazy(
            () =>
              NullableEnumAuxiliaryLabelFieldUpdateOperationsInputObjectSchema,
          ),
        ])
        .optional()
        .nullable(),
      instructionTr: z
        .union([
          z.lazy(() => JsonNullValueInputSchema),
          z.object({ en: z.string(), th: z.string() }),
        ])
        .optional(),
      sideEffectTr: z
        .union([
          z.lazy(() => JsonNullValueInputSchema),
          z.object({ en: z.string(), th: z.string() }),
        ])
        .optional(),
      cautionTr: z
        .union([
          z.lazy(() => JsonNullValueInputSchema),
          z.object({ en: z.string(), th: z.string() }),
        ])
        .optional(),
      pharmacyNote: z
        .union([
          z.string(),
          z.lazy(() => NullableStringFieldUpdateOperationsInputObjectSchema),
        ])
        .optional()
        .nullable(),
      ingredient: z
        .union([
          z.string(),
          z.lazy(() => NullableStringFieldUpdateOperationsInputObjectSchema),
        ])
        .optional()
        .nullable(),
      type: z
        .union([
          z.lazy(() => ProductTypeSchema),
          z.lazy(() => EnumProductTypeFieldUpdateOperationsInputObjectSchema),
        ])
        .optional(),
      drugType: z
        .union([
          z.lazy(() => DrugTypeSchema),
          z.lazy(
            () => NullableEnumDrugTypeFieldUpdateOperationsInputObjectSchema,
          ),
        ])
        .optional()
        .nullable(),
      salesType: z
        .union([
          z.lazy(() => SalesTypeSchema),
          z.lazy(() => EnumSalesTypeFieldUpdateOperationsInputObjectSchema),
        ])
        .optional(),
      productForms: z
        .union([z.lazy(() => JsonNullValueInputSchema), jsonSchema])
        .optional(),
      skinType: z
        .union([z.lazy(() => JsonNullValueInputSchema), jsonSchema])
        .optional(),
      propertyTr: z
        .union([
          z.lazy(() => JsonNullValueInputSchema),
          z.object({ en: z.string(), th: z.string() }),
        ])
        .optional(),
      concerns: z
        .union([z.lazy(() => JsonNullValueInputSchema), jsonSchema])
        .optional(),
      attributes: z
        .union([z.lazy(() => JsonNullValueInputSchema), jsonSchema])
        .optional(),
      priority: z
        .union([
          z.number(),
          z.lazy(() => IntFieldUpdateOperationsInputObjectSchema),
        ])
        .optional(),
      usage: z
        .union([
          z.lazy(() => UsageSchema),
          z.lazy(() => NullableEnumUsageFieldUpdateOperationsInputObjectSchema),
        ])
        .optional()
        .nullable(),
      useAmount: z
        .union([
          z.number(),
          z.lazy(() => NullableFloatFieldUpdateOperationsInputObjectSchema),
        ])
        .optional()
        .nullable(),
      useUnit: z
        .union([
          z.lazy(() => UnitSchema),
          z.lazy(() => NullableEnumUnitFieldUpdateOperationsInputObjectSchema),
        ])
        .optional()
        .nullable(),
      useFrequency: z
        .union([
          z.lazy(() => UseFrequencySchema),
          z.lazy(() => EnumUseFrequencyFieldUpdateOperationsInputObjectSchema),
        ])
        .optional(),
      useTime: z
        .union([
          z.lazy(() => UseTimeSchema),
          z.lazy(() => EnumUseTimeFieldUpdateOperationsInputObjectSchema),
        ])
        .optional(),
      brandId: z
        .union([
          z.number(),
          z.lazy(() => IntFieldUpdateOperationsInputObjectSchema),
        ])
        .optional(),
      createdAt: z
        .union([
          z.date(),
          z.lazy(() => DateTimeFieldUpdateOperationsInputObjectSchema),
        ])
        .optional(),
      updatedAt: z
        .union([
          z.date(),
          z.lazy(() => DateTimeFieldUpdateOperationsInputObjectSchema),
        ])
        .optional(),
      deletedAt: z
        .union([
          z.date(),
          z.lazy(() => NullableDateTimeFieldUpdateOperationsInputObjectSchema),
        ])
        .optional()
        .nullable(),
      v2DrugItemId: z
        .union([
          z.number(),
          z.lazy(() => NullableIntFieldUpdateOperationsInputObjectSchema),
        ])
        .optional()
        .nullable(),
      categories: z
        .lazy(
          () =>
            ProductCategoryUncheckedUpdateManyWithoutProductNestedInputObjectSchema,
        )
        .optional(),
      variants: z
        .lazy(
          () => VariantUncheckedUpdateManyWithoutProductNestedInputObjectSchema,
        )
        .optional(),
      pictures: z
        .lazy(
          () =>
            ProductPictureUncheckedUpdateManyWithoutProductNestedInputObjectSchema,
        )
        .optional(),
      video: z
        .lazy(
          () =>
            ProductVideoUncheckedUpdateManyWithoutProductNestedInputObjectSchema,
        )
        .optional(),
      productReviewSummary: z
        .lazy(
          () =>
            ProductReviewSummaryUncheckedUpdateOneWithoutProductNestedInputObjectSchema,
        )
        .optional(),
      productOnCustomField: z
        .lazy(
          () =>
            ProductCustomFieldUncheckedUpdateManyWithoutProductNestedInputObjectSchema,
        )
        .optional(),
    })
    .strict()

export const ProductUncheckedUpdateWithoutProductReviewsInputObjectSchema =
  Schema
